import "core-js/modules/es.array.push.js";
import { ref } from 'vue';
import { showNotify, closeNotify, showDialog, showConfirmDialog } from 'vant';
export default {
  name: 'PlaySelection',
  data() {
    return {
      active: ref(0),
      message: '',
      checked: '',
      deviceCode: '',
      tenantIdValue: '',
      accessTokenValue: '',
      ringListData: [{
        id: 596,
        name: '课后初中-网络防诈骗',
        typeName: '通用类-新文件',
        url: 'http://qiniuyun.guodun.org.cn/5abcb31bd1c01f894a74adb8387338b6300c048b673e964356648db6aa654e83.MP3'
      }, {
        id: 595,
        name: '课后初中-诈骗信息识别',
        typeName: '通用类-新文件',
        url: 'http://qiniuyun.guodun.org.cn/06cfa665ef34e559ff0863455a63392ca1b2e8bd0f367a326b4bf09e6703e40a.MP3'
      }, {
        id: 594,
        name: '社交媒体风险多',
        typeName: '通用类-新文件',
        url: 'http://qiniuyun.guodun.org.cn/3e909ca32d1627e7b096de44038176572ae733d2510b74f77834f463e782c92d.MP3'
      }, {
        id: 593,
        name: '诈骗手段多翻新',
        typeName: '通用类-新文件',
        url: 'http://qiniuyun.guodun.org.cn/b706fb42515916967f32b50cbf9db68b079ac51acce584978c1590a6219b4e8e.MP3'
      }, {
        id: 592,
        name: '个人信息不外抛03',
        typeName: '通用类-新文件',
        url: 'http://qiniuyun.guodun.org.cn/d7ef024f767c7693e616927856b79d2e2c0c41983a76aaa1be9ce64c5a76ddea.MP3'
      }, {
        id: 564,
        name: '课间-避开施工场所',
        typeName: '安全类-日常安全',
        url: 'https://qiniu.jetlion668.com/2423099d4422a7aae4febed9f067eda9b9abbbeec6ad47e01b69dbf9ab699149.mp3'
      }, {
        id: 565,
        name: '课间-不吃路边摊',
        typeName: '安全类-食品安全',
        url: 'https://qiniu.jetlion668.com/99518a4b00ec80f3c7c3a4de156c8515ad74d50a282947dba5fedff760e0b55e.mp3'
      }, {
        id: 566,
        name: '课间-地震室内逃生空间',
        typeName: '自然灾害类-防地震',
        url: 'https://qiniu.jetlion668.com/982214febc49f39e5c4ecb8d356aa345828e85192f01cd340a6082e28151f630.mp3'
      }, {
        id: 567,
        name: '课间-高空抛物不可行',
        typeName: '安全类-日常安全',
        url: 'https://qiniu.jetlion668.com/8314e7d8ebcb70bafcccb50c9937e82d63059199203e3a1902350f1603c85395.mp3'
      }, {
        id: 568,
        name: '课间-洪水防范知识',
        typeName: '自然灾害类-综合防范',
        url: 'https://qiniu.jetlion668.com/778960f8c0e53b6f6d7572a10a4556fc719286cc8ebc97fac84bece7936dc7cc.mp3'
      }, {
        id: 569,
        name: '课间-护眼阅读习惯',
        typeName: '预防类-防近视',
        url: 'https://qiniu.jetlion668.com/f467a824d5d2d7c9a11332bc9e1786e6b6c140ee924b0e909e45a905665d3480.mp3'
      }, {
        id: 570,
        name: '课间-回家不逗留',
        typeName: '安全类-校园安全',
        url: 'https://qiniu.jetlion668.com/76db2da0688a7979409c67bc994a502efc16c119443a9b8871d7bfb6f894e734.mp3'
      }, {
        id: 571,
        name: '课间-回家路上不游泳',
        typeName: '预防类-防溺水',
        url: 'https://qiniu.jetlion668.com/a0cc473c65bc3d2dba64d0ca04c5a0b0ae665566f55ba4dd4a695c97e3311527.mp3'
      }, {
        id: 572,
        name: '课间-楼道上下不推挤',
        typeName: '安全类-日常安全',
        url: 'https://qiniu.jetlion668.com/d939c3cb1a5156c3985fea99477e0dc84309cdb14985b017047b9b0d986380a7.mp3'
      }, {
        id: 573,
        name: '课间-盲目攀比要不得',
        typeName: '心理类-情绪管理',
        url: 'https://qiniu.jetlion668.com/0923feb817287caa127a3d3bd7c999ad1973cd6bb4a9e92d4ceb52c6128749eb.mp3'
      }, {
        id: 574,
        name: '课间-食物毒素科普',
        typeName: '安全类-食品安全',
        url: 'https://qiniu.jetlion668.com/c0916ba868698d744713c19d80573b027ac6bda83a9ac64323f76fc428debdfb.mp3'
      }, {
        id: 575,
        name: '课间-消防设备位置找一找',
        typeName: '安全类-消防安全',
        url: 'https://qiniu.jetlion668.com/4bdd20b0446702e26679d09b19405e35a6c1ebd1385bfeac3f79a9c6823b19d8.mp3'
      }, {
        id: 576,
        name: '课间-言于律己宽以待人',
        typeName: '心理类-情绪管理',
        url: 'https://qiniu.jetlion668.com/5b0211c2d489630e54e165c4a459c3519a9534589b319233cf889379368e8060.mp3'
      }, {
        id: 577,
        name: '课间-养成守信好习惯',
        typeName: '心理类-人际关系',
        url: 'https://qiniu.jetlion668.com/6306415161c92eb6008d3387cc589c459248dda9c6f228441e5a76f50e2ffa12.mp3'
      }, {
        id: 578,
        name: '课间-易燃物知识普及',
        typeName: '安全类-消防安全',
        url: 'https://qiniu.jetlion668.com/a401ea43dcaac9c11644cd8642f13f64697a8bc2ca5323bfed8f25ce16590891.mp3'
      }, {
        id: 579,
        name: '课间-游泳自救',
        typeName: '预防类-防溺水',
        url: 'https://qiniu.jetlion668.com/f1d161846dc7843776a8c286d8bb693a52b2f454c33a9e04dc795f168739582a.mp3'
      }, {
        id: 580,
        name: '课间-雨天安全',
        typeName: '自然灾害类-防暴雨水灾',
        url: 'https://qiniu.jetlion668.com/5ec4850c7708c4a0554e534c660568cb9743b0dd22d4730523a3ba975c116763.mp3'
      }, {
        id: 581,
        name: '课间-远离车前车尾',
        typeName: '安全类-交通安全',
        url: 'https://qiniu.jetlion668.com/4212bfb944592199b6ce79b1f64c8fc7542187997f8f230c06fdac0c1ed77620.mp3'
      }]
    };
  },
  watch: {},
  created() {
    this.deviceCode = this.$route.query.deviceCode;
  },
  mounted() {
    this.message = "雨天路滑，请同学们小心脚下，确保安全！\n" + "下雨天要避免走小河、小溪等水域，以免发生溺水事故。如果必须经过水域，请家长陪同并注意安全。\n" + "下雨时，避免在大树下、广告牌下等易积水的地方停留，防止被雷电击中。\n" + "雨天要注意交通安全，慢慢走不要打闹，避免滑倒受伤。";
  },
  methods: {
    //清空文本框
    clear() {
      this.message = "";
    },
    onClickTab() {
      ({
        title
      }) => Toast(title);
    },
    onClickLeft() {
      //跳转主页面
      // this.$router.push({ path: 'MainEquipment'});
      this.$router.go(-1);
    },
    //文本确定按钮点击事件
    textConfirmation() {
      if (this.message === "") {
        showNotify({
          type: 'warning',
          message: '请输入文本！'
        });
      } else {
        //跳转主页面
        // this.$router.go(-1);
        this.$router.push({
          path: '/MainEquipment',
          query: {
            deviceCode1: this.deviceCode,
            text1: this.message,
            type1: "1"
          }
        });
      }
    },
    //方案确定按钮点击事件
    schemeDetermine() {
      if (this.checked === "") {
        showNotify({
          type: 'warning',
          message: '请选择音频！'
        });
      } else {
        let url = "";
        this.ringListData.forEach(item => {
          if (item.id === this.checked) {
            url = item.url;
          }
        });
        if (url != "") {
          //跳转主页面
          // this.$router.go(-1);
          this.$router.push({
            path: '/MainEquipment',
            query: {
              deviceCode1: this.deviceCode,
              text1: url,
              type1: "0"
            }
          });
        } else {
          showNotify({
            type: 'danger',
            message: '数据出错！'
          });
        }
      }
    }
  }
};